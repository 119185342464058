globalThis["__sentryRewritesTunnelPath__"] = undefined;
globalThis["SENTRY_RELEASE"] = {"id":"140baee27d3816830c20452e30e4d9e5154ba27b"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "";
import * as Sentry from '@sentry/nextjs';
import { isBrowser } from 'utils/helpers/isBrowser';

Sentry.init({
  dsn: 'https://756d55fd390223ee05461efd9453bb09@o4508640350306304.ingest.de.sentry.io/4508687231287376',
  integrations: [Sentry.replayIntegration()],
  tracesSampleRate: 0.5,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  debug: false,
  beforeSend: event => {
    if (isBrowser()) {
      if (window.location.hostname === 'localhost' || window.location.hostname === 'stage.marketplace.aws.softex.uz') {
        return null;
      }
    }

    return event;
  }
});
