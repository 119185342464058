import { combineReducers, configureStore } from "@reduxjs/toolkit"
import { createWrapper } from "next-redux-wrapper"
import userReducer from "./reducers/userReducer"
import favouritesReducer from "./reducers/favouritesReducer"
import localeReducer from "./reducers/localeReducer"
import filterReducer from "./reducers/filterReducer";
import dailyActiveUserCoinReducer from './reducers/daily-active-user-coin-reducer';
import planirovkaReducer from './reducers/planirovka-reducer'

const rootReducer = combineReducers({
  userReducer,
  favouritesReducer,
  localeReducer,
  filterReducer,
  dailyActiveUserCoinReducer,
  planirovkaReducer
})

export const setupStore = () => {
  return configureStore({
    reducer: rootReducer,
  })
}

export type RootState = ReturnType<typeof rootReducer>
export type AppStore = ReturnType<typeof setupStore>
export type AppDispatch = AppStore["dispatch"]

export const wrapper = createWrapper<AppStore>(setupStore)
