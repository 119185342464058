import { useEffect, useRef } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Dropdown, Spin } from 'antd';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/reduxHooks';
import { userReducerActions } from '../../../../../store/reducers/userReducer';
import { eventTypes } from '../../../../../utils/constants/eventTypes';
import { PHONE_MASK } from '../../../../../utils/constants/inputMasks';
import { localStorageKeys } from '../../../../../utils/constants/localStorageKeys';
import { useLogout } from '../../../services/mutations';
import ReactInputMask from 'react-input-mask';
import LogoutIcon from '../../../assets/icon/LogoutIcon';
import HeaderDropDown from '../header-dropdown/HeaderDropDown';
import { layoutLang } from 'utils/language/layout';
import { useRouter } from 'next/router';
import BonusesIcon from 'assets/icons/BonusesIcon';
import CustomLink from 'common/custom-link';
import User1Icon from 'assets/icons/User1Icon';
import Favourites1Icon from 'assets/icons/Favourites1Icon';
import Favourites2Icon from 'assets/icons/Favourites2Icon';
import { rootPaths } from 'utils/constants/rootPaths';
import PlusIcon from 'assets/icons/plus-icon';
import { useQueryClient } from '@tanstack/react-query';
import { queryKeys } from 'pages/bonuses/utils/constants/queryKeys';
import style from './HeaderTop.module.scss';
import UysotCoinModal from '../../uysot-coin/uysot-coin-modal';
import ReferralIcon from 'assets/icons/referral-icon';
function HeaderTop() {
  const qc = useQueryClient();
  const {
    locale,
    pathname,
    replace,
    query
  } = useRouter();
  const logout = useLogout();
  const dispatch = useAppDispatch();
  const {
    setVisibleLoginModal
  } = userReducerActions;
  const setUserAddresBtnRef = useRef<HTMLButtonElement>(null);
  const favorites_count = useAppSelector(state => state?.userReducer?.favorites_count);
  const complexId = useAppSelector(state => state.userReducer?.complexId);
  const brandId = useAppSelector(state => state.userReducer?.brandId);
  const isAuth = useAppSelector(state => state?.userReducer?.isAuth);

  // open login modal
  const openLoginModal = () => {
    dispatch(setVisibleLoginModal(true));
  };

  // handle set user address
  const handleSetUserAddress = () => {
    setUserAddresBtnRef.current?.click();
  };

  // add event listener
  useEffect(() => {
    window.addEventListener(eventTypes.CLICK, handleSetUserAddress);
    return () => {
      window.removeEventListener(eventTypes.CLICK, handleSetUserAddress);
    };
  }, []);

  // logout
  const handleLogout = () => {
    if (pathname.includes('bonuses')) {
      replace('/');
    } else {
      logout.mutateAsync().then(() => {
        qc.invalidateQueries({
          queryKey: [queryKeys.UYSOT_COIN]
        });
        if (query?.builderId) {
          qc.invalidateQueries({
            queryKey: [queryKeys.BUILDER_BONUSES, brandId]
          });
        } else {
          qc.invalidateQueries({
            queryKey: [queryKeys.ONE_COMPLEX, String(complexId)]
          });
        }
      });
    }
    logout.mutateAsync().then(() => {
      if (query?.builderId) {
        qc.prefetchQuery({
          queryKey: [queryKeys.BUILDER_BONUSES, brandId]
        });
      } else {
        qc.prefetchQuery({
          queryKey: [queryKeys.ONE_COMPLEX, String(complexId)]
        });
      }
    });
  };
  return <div className={style.header_right} data-sentry-component="HeaderTop" data-sentry-source-file="HeaderTop.tsx">
      <CustomLink href={rootPaths.FAVOURITES} rel="noopener noreferrer" className="relative flex cursor-pointer items-center gap-3 px-3 py-2.5" data-sentry-element="CustomLink" data-sentry-source-file="HeaderTop.tsx">
        {favorites_count! > 0 ? <Favourites1Icon /> : <Favourites2Icon />}
      </CustomLink>
      <div className="desctop_only">
        <UysotCoinModal data-sentry-element="UysotCoinModal" data-sentry-source-file="HeaderTop.tsx" />
      </div>
      <div className="ml-3">
        <HeaderDropDown type="currency" data-sentry-element="HeaderDropDown" data-sentry-source-file="HeaderTop.tsx" />
      </div>
      <CustomLink href="https://t.me/uysot_admin" target="_blank" rel="noopener noreferrer" className="ml-4 flex h-8 w-max items-center gap-2 rounded-[10px] bg-[#EAECF0] px-3 py-2" data-sentry-element="CustomLink" data-sentry-source-file="HeaderTop.tsx">
        <PlusIcon stroke='#344054' data-sentry-element="PlusIcon" data-sentry-source-file="HeaderTop.tsx" />
        <span className="hidden text-sm font-semibold text-[#344054] md:block">
          {layoutLang[locale || 'ru']?.['Объявления']}
        </span>
      </CustomLink>
      <>
        {isAuth ? <div className={style.header_right_login}>
            {/* @ts-ignore */}
            <ReactInputMask mask={PHONE_MASK} value={localStorage.getItem(localStorageKeys.PHONE) ?? ''} disabled />
            <Dropdown trigger={['click', 'hover']} mouseEnterDelay={1000} mouseLeaveDelay={0.3} overlay={<div className={`select-none rounded-lg border border-solid border-[#F9FAFB] bg-white`} style={{
          boxShadow: '0px 4px 6px -2px #10182808, 0px 12px 16px -4px #10182814'
        }}>
                  <CustomLink href="/bonuses" className="relative flex cursor-pointer items-center gap-3 border-b border-solid border-[#F9FAFB] px-4 py-2.5">
                    <BonusesIcon />
                    <p className="text-[#000000D9]">{layoutLang[locale || 'ru']['Bonuslar']}</p>
                  </CustomLink>
                  <CustomLink href="/referral" className="relative flex cursor-pointer items-center gap-3 border-b border-solid border-[#F9FAFB] px-4 py-2.5">
                    <ReferralIcon />
                    <p className="text-[#000000D9]">{layoutLang[locale || 'ru']['Referral']}</p>
                  </CustomLink>
                  <p onClick={handleLogout} aria-hidden className="relative flex cursor-pointer items-center gap-3 px-4 py-2.5">
                    {logout.isLoading ? <Spin indicator={<LoadingOutlined style={{
              fontSize: 20
            }} spin />} /> : <LogoutIcon />}
                    <p>{layoutLang[locale || 'ru']['Выйти']}</p>
                  </p>
                </div>}>
              <div className="ml-3 flex h-9 w-9 cursor-pointer items-center justify-center gap-2.5 rounded-full bg-[#F2F4F7]">
                <User1Icon />
              </div>
            </Dropdown>
          </div> : <div className={`${style.header_right_login} ml-3 h-8 rounded-[10px] bg-[#4A4A4A] px-3 py-2 text-sm font-semibold text-white`} onClick={openLoginModal}>
            {layoutLang[locale || 'ru']?.['login']}
          </div>}
      </>


    </div>;
}
export default HeaderTop;