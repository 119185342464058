'use-client';

import UserFeedbackModal from 'pages/main-layout/components/user-feedback-modal';
import React, { ReactNode, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import NonSSRWrapper from '../../common/non-ssr/NonSSRWrapper';
import Footer from './components/footer/Footer';
import LoginModal from './components/login-modal/LoginModal';
import MobileAppDownload from './components/mobile-app-download/MobileAppDownload';
import MobileMenu from './components/mobile-menu/MobileMenu';
import FooterFilter from './components/footer-filter/FooterFilter';
import MobileFooterMenu from './components/mobile-footer-menu/MobileFooterMenu';
import styles from './index.module.scss';
import LangDropdown from './components/header/bottom/LangDropdown';
import MenuCurrency from './components/mobile-menu/menu-currency/MenuCurrency';
import NavigateButton from './components/navigate-button/NavigateButton';
import { useCheckAuth, useViewUserNotif } from './services/queries';
import LayoutHeader from 'common/layout-header/LayoutHeader';
import { Spin } from 'antd';
import { isValidUrl } from 'utils/helpers/isValidUrl';
import { Inter } from 'next/font/google';
import DailyActiveUserCoinModalDrawer from 'pages/app/components/daily-actice-user-coin-modal-drawer';
import BigPrizeModal from 'pages/app/components/big-prize-modal';
import GetBigPrizeModal from 'pages/app/components/get-big-prize-modal';
import { sendRequest } from 'pages/firebase/firebase';
import { useCreateDeviceToken, useDeleteDeviceToken } from 'pages/app/services/mutations';
import { isBrowser } from 'utils/helpers/isBrowser';
const inter = Inter({
  subsets: ['cyrillic', 'latin'],
  weight: ['400', '500', '600', '700']
});
type Props = {
  children: ReactNode;
};
const MainLayout: React.FC<Props> = ({
  children
}) => {
  useCheckAuth();
  const router = useRouter();
  const [loading, setLoading] = useState(false);
  const [deviceToken, setToken] = useState('');
  const [notifPermission, setNotifPermission] = useState('');
  const deleteDeviceToken = useDeleteDeviceToken();
  const createDeviceToken = useCreateDeviceToken();
  const [notificationKey, setNotificationKey] = useState<string | null>(null);

  // Store notification key when component mounts
  useEffect(() => {
    if (router.query.notification_key) {
      setNotificationKey(router.query.notification_key as string);

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const {
        notification_key,
        ...queryWithoutNotifKey
      } = router.query;
      router.replace({
        pathname: router.pathname,
        query: queryWithoutNotifKey
      }, undefined, {
        shallow: true
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.query.notification_key]);

  // Trigger the query based on the stored key
  useViewUserNotif(notificationKey as string);
  const inNavigateBtn = (router.pathname.includes('karta') || router.pathname.includes('kvartiri') || router.pathname.includes('novostroyki')) && !router?.query?.apartmentId && !router?.query?.id || router?.query?.id && isValidUrl(router?.query?.id as string) || router?.query?.apartmentId && isValidUrl(router?.query?.apartmentId as string);
  useEffect(() => {
    const handleStart = () => {
      setLoading(true);
    };
    const handleStop = () => {
      setLoading(false);
    };
    router.events.on('routeChangeStart', handleStart);
    router.events.on('routeChangeComplete', handleStop);
    router.events.on('routeChangeError', handleStop);
    return () => {
      router.events.off('routeChangeStart', handleStart);
      router.events.off('routeChangeComplete', handleStop);
      router.events.off('routeChangeError', handleStop);
    };
  }, [router]);
  useEffect(() => {
    if (router?.pathname?.includes('karta')) {
      document.body.style.overflowY = 'hidden';
    } else {
      document.body.style.overflowY = 'auto';
    }
  }, [router.pathname]);
  useEffect(() => {
    if (isBrowser()) {
      // Run sendRequest only once on mount
      sendRequest(setToken, setNotifPermission);
    }
  }, []); // Empty dependency array ensures it runs only once on mount

  useEffect(() => {
    if (!isBrowser()) return;
    const storageDeviceToken = localStorage.getItem('deviceToken');

    // Only proceed if mutations are not already in progress
    if (deleteDeviceToken.isLoading || createDeviceToken.isLoading || deleteDeviceToken.isError || createDeviceToken.isError) return;

    // Delete token if permission is denied and token exists
    if (notifPermission && notifPermission !== 'granted' && storageDeviceToken) {
      if (!deleteDeviceToken.isSuccess) {
        // Ensure it hasn't already succeeded
        deleteDeviceToken.mutate({
          device_token: storageDeviceToken
        });
      }
    }
    // Create token if deviceToken is available
    else if (deviceToken && !createDeviceToken.isSuccess) {
      // Ensure it hasn't already succeeded
      createDeviceToken.mutate({
        device_token: deviceToken
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notifPermission, deviceToken, deleteDeviceToken.isLoading, createDeviceToken.isLoading, deleteDeviceToken.isSuccess, createDeviceToken.isSuccess, deleteDeviceToken.isError, createDeviceToken.isError]);
  return <>
      <div className={`${styles.layout} ${inter.className} ${router?.pathname?.includes('karta') && '!overflow-y-hidden'} `} id="layout">
        <LayoutHeader data-sentry-element="LayoutHeader" data-sentry-source-file="Index.tsx" />
        <NonSSRWrapper data-sentry-element="NonSSRWrapper" data-sentry-source-file="Index.tsx">
          <MobileMenu data-sentry-element="MobileMenu" data-sentry-source-file="Index.tsx" />
        </NonSSRWrapper>
        <main>
          <Spin className="!fixed z-[10000000] !max-h-screen" spinning={loading} data-sentry-element="Spin" data-sentry-source-file="Index.tsx">
            {children}
          </Spin>
        </main>

        <div className="removed_item">
          <NonSSRWrapper data-sentry-element="NonSSRWrapper" data-sentry-source-file="Index.tsx">
            <LangDropdown data-sentry-element="LangDropdown" data-sentry-source-file="Index.tsx" />
          </NonSSRWrapper>
          <NonSSRWrapper data-sentry-element="NonSSRWrapper" data-sentry-source-file="Index.tsx">
            <MenuCurrency data-sentry-element="MenuCurrency" data-sentry-source-file="Index.tsx" />
          </NonSSRWrapper>
          {!router.pathname.includes('karta') && <>
              <FooterFilter />
              <MobileAppDownload />
              <Footer />
            </>}
          {inNavigateBtn && <div className="mobile_only">
              <NavigateButton position={true} />
            </div>}
          <MobileFooterMenu data-sentry-element="MobileFooterMenu" data-sentry-source-file="Index.tsx" />
        </div>
      </div>
      <LoginModal data-sentry-element="LoginModal" data-sentry-source-file="Index.tsx" />
      <NonSSRWrapper data-sentry-element="NonSSRWrapper" data-sentry-source-file="Index.tsx">
        <UserFeedbackModal data-sentry-element="UserFeedbackModal" data-sentry-source-file="Index.tsx" />
      </NonSSRWrapper>
      <NonSSRWrapper data-sentry-element="NonSSRWrapper" data-sentry-source-file="Index.tsx">
        <DailyActiveUserCoinModalDrawer data-sentry-element="DailyActiveUserCoinModalDrawer" data-sentry-source-file="Index.tsx" />
        <BigPrizeModal data-sentry-element="BigPrizeModal" data-sentry-source-file="Index.tsx" />
        <GetBigPrizeModal data-sentry-element="GetBigPrizeModal" data-sentry-source-file="Index.tsx" />
      </NonSSRWrapper>
    </>;
};
export default MainLayout;