import React, { useEffect } from 'react';
import { useRouter } from 'next/router';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { favouritesReducerActions } from 'store/reducers/favouritesReducer';
import Header from 'pages/main-layout/components/header/Header';
import FilterDesktop from 'common/filter-desktop/FilterDesktop';
import CommonBreadCrumbs from 'common/common-bread-crumbs/common-bread-crumbs';
import styles from './layoutHeader.module.scss';
import { isValidUrl } from 'utils/helpers/isValidUrl';
import { Grid } from 'antd';
const LayoutHeader = () => {
  const {
    pathname,
    query
  } = useRouter();
  const dispatch = useAppDispatch();
  const filterVisible = useAppSelector(state => state.favouritesReducer.filterVisible);
  const {
    setFilterVisible
  } = favouritesReducerActions;
  const {
    md
  } = Grid.useBreakpoint();
  useEffect(() => {
    let lastScrollY = window.scrollY;
    let ticking = false;
    const SCROLL_THRESHOLD = 20;
    const updateScrollState = () => {
      const currentScrollY = window.scrollY;
      const isScrollingUp = currentScrollY < lastScrollY;
      const scrollDelta = Math.abs(currentScrollY - lastScrollY);
      if (isScrollingUp || currentScrollY <= 0) {
        dispatch(setFilterVisible(true));
      } else if (scrollDelta > SCROLL_THRESHOLD) {
        dispatch(setFilterVisible(false));
      }
      lastScrollY = currentScrollY;
      ticking = false;
    };
    const handleScroll = () => {
      if (!ticking) {
        window.requestAnimationFrame(updateScrollState);
        ticking = true;
      }
    };
    window.addEventListener("scroll", handleScroll, {
      passive: true
    });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [dispatch, setFilterVisible]);
  const isPad = pathname === "/[location]/kvartiri" || pathname === "/[location]/novostroyki" || query?.apartmentId && isValidUrl(query?.apartmentId as string) || query?.id && isValidUrl(query?.id as string);
  const isDlPage = pathname.includes('dl');
  const isDetailedArticleInWebView = pathname.includes('/jurnal') && query?.id;
  const isFilterPage = pathname === "/[location]/novostroyki" || pathname === "/[location]/kvartiri" || query?.apartmentId && isValidUrl(query?.apartmentId as string) || query?.id && isValidUrl(query?.id as string) || pathname === "/karta";
  return <>
      {!md && <div className={`${isPad ? 'pb-24' : isDlPage ? "md:pb-[70px] pb-16" : "md:pb-[105px] pb-[94px]"} removed_item`} />}
      <div className='w-full bg-white'>
        <header className={`${!md ? styles.header : ''} ${filterVisible ? !md ? styles.visible : '' : !md ? styles.hidden : ''}  ${isDetailedArticleInWebView ? 'removed_item' : ''}`}>
          <Header data-sentry-element="Header" data-sentry-source-file="LayoutHeader.tsx" />
        </header>
      </div>
      <div className={`desctop_only sticky transition-transform duration-[0.15s] ease-out flex justify-start items-center bg-white top-0 z-[1000] h-[62px] ${!filterVisible ? 'border-b border-solid border-gray-200 shadow-sm' : ''} ${pathname === "/karta" ? 'shadow-sm' : ''} ${isFilterPage ? 'flex' : 'hidden'}`}>
        {(pathname === "/[location]/novostroyki" || pathname === "/[location]/kvartiri" || query?.apartmentId && isValidUrl(query?.apartmentId as string) || query?.id && isValidUrl(query?.id as string) || pathname === "/karta") && <FilterDesktop />}
      </div>

      <CommonBreadCrumbs data-sentry-element="CommonBreadCrumbs" data-sentry-source-file="LayoutHeader.tsx" />
    </>;
};
export default LayoutHeader;