import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { useRouter } from "next/router";
import { favouritesReducerActions } from "../../store/reducers/favouritesReducer";
import { isEmptyArr } from "utils/helpers/isEmptyArr";
import { layoutLang } from "utils/language/layout";
import { DEFAULT_LOCATION } from "utils/constants/defaultLocation";
import { findMatchingKey } from "utils/helpers/findMatchingKey";
import { rootPaths } from "utils/constants/rootPaths";
import { removeObjProperties } from "utils/helpers/removeObjProperties";
import { makeStaticFilters } from "utils/helpers/makeStaticFilters";
import { cleanObject } from "utils/helpers/cleanObject";
import SearchIcon from "../../assets/icons/SearchIcon";
import FilterDesktopModal from "./filter-desktop-modal/FilterDesktopModal";
import FilterRoomModal from "./filter-room-modal/FilterRoomModal";
import FilterPriceModal from "./filter-price-modal/FilterPriceModal";
import FilterMetro from "pages/main/components/filter-metro/FilterMetro";
import SearchLocation from "pages/main/components/search-location/SearchLocation";
import { LOCATION } from "utils/constants/queryParamsKeys";
const FilterDesktop = () => {
  const dispatch = useAppDispatch();
  const {
    push,
    query,
    pathname,
    locale
  } = useRouter();
  const {
    filter,
    filterType,
    location: {
      regionValue
    }
  } = useAppSelector(state => state.favouritesReducer);
  const filterSearchValue = useAppSelector(state => state.filterReducer.filterSearchValue);
  const openDesktopModal = useAppSelector(state => state.favouritesReducer.openDesktopModal);
  const {
    complexes,
    metro_lines,
    nearest_places,
    brands
  } = useAppSelector(state => state.filterReducer);
  const location = useAppSelector(state => state.favouritesReducer.location);
  const {
    setChangeComplexName,
    setOpenMapDrawer,
    setStudio,
    setPayments,
    setClasses,
    setRepaired,
    setMaxMonthlyPayment,
    setMinMonthlyPayment,
    setMinPrePayment,
    setMaxPrePayment,
    setMaxAreaPrice,
    setMinAreaPrice,
    setMaxTotalPrice,
    setMinTotalPrice,
    setCurrentRegion,
    setDistanceMetro,
    setChangeFilterType
  } = favouritesReducerActions;
  const viewType = pathname.includes("karta");
  const staticFilters = makeStaticFilters(query?.apartmentId as string || query?.id as string);
  const complexIds = complexes && !isEmptyArr(complexes) ? complexes.map(item => item.id) : null;
  const metroIds = metro_lines && !isEmptyArr(metro_lines) ? metro_lines.map(item => item.id) : null;
  const nearestPlaceIds = nearest_places && !isEmptyArr(nearest_places) ? nearest_places.map(item => item.id) : null;
  const brandIds = brands && !isEmptyArr(brands) ? brands.map(item => item.id) : null;
  useEffect(() => {
    if (query?.complex_name) dispatch(setChangeComplexName(String(query?.complex_name)));
    if (staticFilters?.rooms_count) dispatch(setStudio(staticFilters?.rooms_count));
    if (staticFilters?.payment_method_ids) dispatch(setPayments(staticFilters?.payment_method_ids));
    if (staticFilters.repaired) dispatch(setRepaired(staticFilters?.repaired));
    if (staticFilters.class_ids) dispatch(setClasses(staticFilters.class_ids));
    if (query?.rooms_count) {
      if (Array.isArray(query?.rooms_count)) {
        dispatch(setStudio(query?.rooms_count));
      } else {
        dispatch(setStudio([query?.rooms_count]));
      }
    }
    if (query?.complex_nearest_place_duration) {
      dispatch(setDistanceMetro(query?.complex_nearest_place_duration as string));
    }
    if (query?.max_monthly_price) {
      dispatch(setMaxMonthlyPayment(query?.max_monthly_price !== null ? Number(query?.max_monthly_price) : null));
    }
    if (query?.min_monthly_price) {
      dispatch(setMinMonthlyPayment(query?.min_monthly_price !== null ? Number(query?.min_monthly_price) : null));
    }
    if (query?.min_prepayment) {
      dispatch(setMinPrePayment(query?.min_prepayment !== null ? Number(query?.min_prepayment) : null));
    }
    if (query?.max_prepayment) {
      dispatch(setMaxPrePayment(query?.max_prepayment !== null ? Number(query?.max_prepayment) : null));
    }
    if (query?.min_price) {
      dispatch(setMinAreaPrice(query?.min_price !== null ? Number(query?.min_price) : null));
    }
    if (query?.max_price) {
      dispatch(setMaxAreaPrice(query?.max_price !== null ? Number(query?.max_price) : null));
    }
    if (query?.min_total_price) {
      dispatch(setMinTotalPrice(query?.min_total_price !== null ? Number(query?.min_total_price) : null));
    }
    if (query?.max_total_price) {
      dispatch(setMaxTotalPrice(query?.max_total_price !== null ? Number(query?.max_total_price) : null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, openDesktopModal, pathname]);
  useEffect(() => {
    if (viewType) {
      if (query?.type) {
        dispatch(setChangeFilterType(query?.type as "apartment" | "complex"));
      } else {
        dispatch(setChangeFilterType(filterType as "apartment" | "complex"));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, pathname]);
  const currentLocation = regionValue?.district ? `${regionValue.district?.city?.url || ""}-${regionValue.district.url}` : regionValue?.city?.url || DEFAULT_LOCATION;
  const newValues = cleanObject({
    ...filter,
    search: filterSearchValue,
    complex_ids: complexIds,
    metro_line_ids: metroIds,
    nearest_place_ids: nearestPlaceIds,
    brand_ids: brandIds,
    page: viewType ? undefined : "1",
    currency: query?.currency
  });
  const onFinish = () => {
    const isComplex = filterType === "complex";
    const cleanedQuery = cleanObject(newValues);
    const matchedKey = findMatchingKey(removeObjProperties(cleanedQuery, 'location', 'page', 'apartmentId', 'id', 'city_id'));
    const mapQuery = {
      ...cleanedQuery,
      ...(viewType && {
        type: filterType
      }),
      ...(viewType && currentLocation !== DEFAULT_LOCATION && {
        [LOCATION]: currentLocation
      })
    };
    if (!viewType && matchedKey) {
      push({
        pathname: `/${currentLocation}${isComplex ? rootPaths.RESIDENTIAL_COMPLEXES : rootPaths.APARTMENTS}/${matchedKey}`
      }).then(() => {
        dispatch(setCurrentRegion({
          type: 'hot_location',
          value: location?.regionValue
        }));
      });
    } else {
      push({
        pathname: `${viewType ? "/karta" : `/${currentLocation}/${isComplex ? "novostroyki" : "kvartiri"}`}`,
        query: mapQuery
      }).then(() => {
        dispatch(setCurrentRegion({
          type: 'hot_location',
          value: location?.regionValue
        }));
      });
    }
  };
  return <div className="w-full h-full" data-sentry-component="FilterDesktop" data-sentry-source-file="FilterDesktop.tsx">
      <div className={`flex items-center justify-between max-w-[1254px] h-full mx-auto`} onClick={() => dispatch(setOpenMapDrawer({
      id: null,
      open: false
    }))}>
        <div className="flex items-center gap-4 flex-1">
          <FilterRoomModal data-sentry-element="FilterRoomModal" data-sentry-source-file="FilterDesktop.tsx" />
          <FilterPriceModal type="complex" data-sentry-element="FilterPriceModal" data-sentry-source-file="FilterDesktop.tsx" />
          <SearchLocation type="desktop" data-sentry-element="SearchLocation" data-sentry-source-file="FilterDesktop.tsx" />
          <FilterMetro type="header" data-sentry-element="FilterMetro" data-sentry-source-file="FilterDesktop.tsx" />
        </div>
        <div className="flex items-center justify-end gap-2 flex-1">
          <FilterDesktopModal data-sentry-element="FilterDesktopModal" data-sentry-source-file="FilterDesktop.tsx">
            <div className='flex justify-center items-center h-9 bg-[#F9FAFB] rounded-[10px] cursor-pointer border border-solid border-[#D0D5DD] px-2 text-[#344054] text-sm font-semibold min-w-[68px]'>{layoutLang[locale || 'ru']["Фильтр"]}</div>
          </FilterDesktopModal>
          <div onClick={onFinish} className="flex items-center rounded-[10px] h-9 gap-2 p-[10px] bg-[#00A389] text-[15px] font-medium leading-[18px] text-white cursor-pointer">
            <SearchIcon size="20" color="#fff" data-sentry-element="SearchIcon" data-sentry-source-file="FilterDesktop.tsx" />
            <span>{layoutLang[locale || 'ru']["Найти"]}</span>
          </div>
        </div>
      </div>
    </div>;
};
export default FilterDesktop;